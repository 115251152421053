:root {
    --primary-color: rgb(241, 156, 11);
    --secondary-color: rgb(12, 95, 240);
}

:focus-visible,
*:focus-visible,
a:focus-visible,
button:focus-visible,
input:focus-visible,
textarea:focus-visible
{
    outline: 2px;
    outline-offset: 2px !important;
    outline-style: solid;
    outline-color: var(--secondary-color) !important;
    box-shadow: 0 0 0 4px transparent !important;
}

/*a:hover*/
/*{*/
/*    color: var(--primary-color);*/
/*}*/

::selection {
    background-color: var(--primary-color);
    color: black;
}

::-webkit-input-placeholder {
    color: #a0a0a0;
}

/*input, textarea {*/
/*    caret-color: blue;*/
/*}*/

::-webkit-scrollbar {
    width: 8px;
}

::-webkit-scrollbar-track {
    background: #192335 !important;
    z-index: 40;
}

::-webkit-scrollbar-thumb {
    background: var(--primary-color) !important;
    border-radius: 0px !important;
    z-index: 40;
}

::-webkit-scrollbar-thumb:hover {
    background: #ffffff;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    -webkit-text-fill-color: rgb(14, 164, 231);
    background-color: rgba(0, 0, 0, 0);
    transition: background-color 5000s ease-in-out 0s;
}