@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
    /*.hw-full {*/
    /*    height: 100%;*/
    /*    width: 100%;*/
    /*}*/


}